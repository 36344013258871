import React, { createContext, Component } from 'react'
import http from '../http'

const SharedStorageContext = createContext()

class SharedStorage extends Component {

  constructor( props ) {
    super( props )
    this.state = {}
    this.updateStorege = this.updateStorege.bind( this )
  }

  updateStorege( data ) {
    this.setState( ( state, props ) => ( {
      ...state, ...data
    } ) )
  }

  render() {
    const { state: storage, updateStorege } = this

    return <SharedStorageContext.Provider
      value={ {
        storage,
        updateStorege
      } }
    >
      { this.props.children }
    </SharedStorageContext.Provider>
  }
}

const withSharedStorage = ( ComponentAlias ) => {
  return props => (
    <SharedStorageContext.Consumer>
      { context => <ComponentAlias { ...props } withSharedStorage={ context } /> }
    </SharedStorageContext.Consumer>
  )
}

export {
  withSharedStorage,
  SharedStorageContext
}

export default SharedStorage
