import { useState, useEffect } from 'react'
import SharedStorage from '../../context/SharedStorage'

import Table from './_Table'

import Reload from '../../context/Reload'
import Select from '../../components/Select'
import http from '../../http'

import { FiCornerLeftUp } from 'react-icons/fi'

const Reports = ( {
  before,
  excludedColumns
} ) => {
  const [ client, setClient ] = useState()
  const [ clientList, setClientList ] = useState( [] )

  useEffect( () => {
    if ( clientList.length !== 0 ) return

    http
      .get( '/api/collection/dataSource/all' )
      .then( response => {
        const availableRemotes = response.data.filter( r => r.collections.filter( c => c.name.startsWith( 'reports' ) ).length > 0 )

        setClientList( availableRemotes )
      } )
  } )

  function onClientChange( e, toggle ) {
    const { value: clientName } = e.target
    const cl = clientList.find( c => c.name === clientName )
    setClient( cl )
    toggle()
  }

  return <main>
    <header>
      <Select
        className="full"
        id="clientSelector"
        name="client"
        multiple={ false }
        value={ client ? client.name : '' }
        onChange={ onClientChange }
        placeholder="selecciona un cliente"
        options={ [
          ...( clientList.map( c => c.name ) )
        ] }
      />
    </header>

    { ! client ? <section className="placeholder centered">
      <div className="vs">
        <FiCornerLeftUp />
        <label>selecciona un cliente</label>
      </div>
    </section> : <section className="padding">
      { client.collections
        .filter( col => col.name.startsWith( 'reports' ) )
        .map( ( schema, index ) => <Reload
          key={ index }
        >
          <SharedStorage><Table
            client={ client }
            schema={ schema }
          /></SharedStorage>
        </Reload> ) }
    </section> }
  </main>
}

export default Reports
