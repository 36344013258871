import React, { useState, useRef, useEffect } from 'react'
import Fetch from '../components/Fetch'
import { withFilter } from '../context/Filter'
import { withColumns } from '../context/Columns'
import Select from '../components/Select'
import Autocomplete from '../components/Autocomplete'
import serialize from '../helpers/serialize'
import flatten from 'flat'
import set from 'set-value'
import moment from 'moment'

import {
  FiCheck,
  FiX
} from 'react-icons/fi'

import {
  AiOutlineClear
} from 'react-icons/ai'

function Filters( props ) {
  const { close, prefix } = props
  const [ columns, setColumns ] = useState( [] )
  const [ model, setModel ] = useState( { ...props.withFilter.state.model } )
  const [ hydrated, setHydrated ] = useState( { ...props.withFilter.state.hydrated } )
  const formElem = useRef( null )

  useEffect( () => {
    setColumns( props.withColumns.columns
      .reduce( ( acc, cur ) => {
        const { __filter: filter, ...rest } = cur

        let items = [ { ...rest, ...filter } ]
        if ( filter instanceof Array ) {
          items = filter.map( f => ( {
            ...rest,
            ...f
          } ) )
        }

        return [ ...acc, ...items ]
      }, [] )
    )
  }, [ props.withColumns.columns ] )

  function submit() {
    const parsedVariables = serialize( hydrated )

    const steps = Object
      .keys( parsedVariables )
      .map( k => {
        const f = columns.find( ff => ff.identity === k )

        if ( ! f ) return null

        let value = parsedVariables[ k ]

        // date variables
        if ( f.type === 'string' && f.format === 'date-time' ) {
          const stringifiedF = JSON.stringify( f.__external.match )
          const isLower = /\$lte?/.test( stringifiedF )

          if ( isLower )
            value = moment.utc( value ).endOf( 'day' ).toISOString()
          else
            value = moment.utc( value ).startOf( 'day' ).toISOString()
        }

        return JSON.parse(
          JSON
            .stringify( f.__external )
            .replaceAll(
              '"VALUE"',
              JSON.stringify( value )
            )
        )
      } )
      .reduce( ( acc, cur ) => {
        if ( acc.length === 0 )
          return [ cur ]

        let group = acc.find( a => a.from === cur.from && a.localField === cur.localField && a.foreignField === cur.foreignField )

        if ( ! group )
          return [ ...acc, cur ]

        const flatMatch = flatten( cur.match )
        Object
          .keys( flatMatch )
          .forEach( m => set( group.match, m, flatMatch[ m ] ) )

        return acc
      }, [] )
      .map( item => ( { __external: item } ) )

    props.withFilter.update( {
      model,
      hydrated,
      steps
    } )
    close()
  }

  function clear() {
    setModel( {} )
    setHydrated( {} )
    props.withFilter.reset()
    formElem.current.reset()
    close()
  }

  function handleChange( event ) {
    const { name, type, value, id } = event.target
    const before = model[ name ]

    if ( value === '' ) {
      const h = { ...hydrated }
      const m = { ...model }
      delete h[ id ]
      delete m[ name ]
      setHydrated( { ...h } )
      setModel( { ...m } )
      return
    }

    let aux
    switch( type ) {
      case 'checkbox':
        aux = new Set()
        if ( before instanceof Set )
          aux = before

        if ( aux.has( value ) ) {
          aux.delete( value )
          break
        }

        aux.add( value )

        break

      case 'number':
        aux = Number( value )
        break

      default:
        aux = value
    }

    setHydrated( { ...hydrated, [ id ]: aux } )
    setModel( { ...model, [ name ]: aux } )
  }

  function buildField( field ) {
    if ( field.enum ) {
      return <div
        key={ field.identity }
        className="form-group"
      >
        <label>
          { field.label || field.as || field.name }
        </label>

        <Select
          id={ field.identity }
          name={ field.identity }
          value={ model[ field.identity ] }
          options={ field.enum }
          onChange={ handleChange }
          placeholder={ field.placeholder || field.label || field.as || field.name }
        />
      </div>
    }

    if ( field.__autocomplete ) {
      const rest = {
        ...field.__autocomplete,
        endpoint: `/api/dataSource/${ props.client }/${ field.__autocomplete.collection }/autocomplete`
      }

      return <div
        key={ field.name }
        className="form-group"
      >
        <label>
          { field.label || field.as || field.name }
        </label>

        <Autocomplete
          id={ field.identity }
          name={ field.identity }
          placeholder={ field.placeholder || field.label || field.as || field.name }
          value={ model[ field.identity ] }
          onSelect={ handleChange }
          { ...rest }
        />

      </div>
    }

    if ( field.__select ) {
      let ep = field.__select.endpoint
      if ( prefix ) {
        const parts = ep.match( /.*\/([^\/]+)$/ )
        const action = parts.pop()
        ep = `${ prefix }/${ action }`
      }

      return <div
        key={ field.name }
        className="form-group"
      >
        <label>
          { field.label || field.as || field.name }
        </label>

        <Fetch
          endpoint={ ep }
          params={ field.__select.params }
        >
          { ( data ) => (
            <Select
              id={ field.identity }
              name={ field.name }
              value={ model[ field.name ] }
              options={ data || [] }
              onChange={ handleChange }
              placeholder={ field.placeholder || field.label || field.as || field.name }
            />
          ) }
        </Fetch>
      </div>
    }

    let type = 'text'

    if ( field.type === 'string' && field.format === 'date-time' )
      type = 'date'

    if ( field.type === 'integer' )
      type = 'number'

    return <div
      key={ field.identity }
      className="form-group"
    >
      <label>
        { field.label || field.as || field.name }
      </label>

      <input
        id={ field.identity }
        type={ type }
        name={ field.identity }
        value={ model[ field.identity ] }
        size="1"
        onChange={ handleChange }
        placeholder={ field.placeholder || field.label || field.as || field.name }
      />
    </div>
  }

  const fields = columns
    .filter( el => ! el.hidden )
    .map( ( el, i ) => {
      return buildField( el )
    } )

  return (
    <div className="modal x50">
      <main>
        <header>
          <h1>Filtrar</h1>

          <div className="actions">
            <button title="aplicar filtros" onClick={ submit }>
              <FiCheck />
            </button>

            <button title="eliminar filtros" onClick={ clear }>
              <AiOutlineClear />
            </button>

            <button title="cancelar" onClick={ close }>
              <FiX />
            </button>
          </div>
        </header>

        <section>
          <form ref={ formElem } className="filters">
            { fields }
          </form>
        </section>
      </main>
    </div>
  )
}

export default withFilter( withColumns( Filters ) )
