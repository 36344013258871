import React, { useState } from 'react'
import { withList } from '../../../../context/List'
import DeleteSelected from './_DeleteSelected'
import DeleteAll from './_DeleteAll'

function Delete( props ) {
  const {
    client
  } = props

  return (
    <div className="vs inline-controls">
      <DeleteSelected
        client={ client }
      />

      <DeleteAll
        client={ client }
      />
    </div>
  )
}

export default withList( Delete )
