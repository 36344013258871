import { useState, useEffect } from 'react'
import http from 'http'
import { withSharedStorage } from 'context/SharedStorage'

import CopyField from './_CopyField'

import {
  FiArrowLeft
} from 'react-icons/fi'

const Product = ( {
  model,
  setModel,
  close,
  withSharedStorage: { storage: { client } },
} ) => {
  const [ product, setProduct ] = useState()

  useEffect( () => {
    if ( ! model.IdObraInterno || ! model.Identificador ) return

    http( {
      url: `/api/proxy/${ client.name }/catalog`,
      method: 'GET',
      params: {
        obra_interno: model.IdObraInterno,
        identificador: model.Identificador
      },
      silence: true
    } )
      .then( ( { data } ) => {
        setProduct( data )
      } )
      .catch( err => {
        setProduct()
      } )
  }, [ model.IdObraInterno, model.Identificador ] )

  if ( ! product ) return null

  return <main>
    <header>

      <div className="actions">
        <button onClick={ close }>
          <FiArrowLeft />
        </button>
      </div>

      <h1>producto</h1>

    </header>

    <section>
      <div className="hs gap padding">

        <div className="vs">
          <CopyField
            name="_id"
            value={ product._id }
          />
        </div>

        <div className="vs">
          <CopyField
            name="titulo"
            value={ product.titulo }
            span="two"
          />

          <CopyField
            name="tipo_obra"
            value={ product.tipo_obra }
          />
        </div>

        <div className="vs">
          <CopyField
            name="identificador"
            value={ product.identificador }
          />

          <CopyField
            name="obra_interno"
            value={ product.obra_interno }
          />
        </div>

        <div className="vs">
          <CopyField
            name="autor bibliogr&aacute;fico"
            value={ product.autor_bibliografico }
          />
        </div>

        { product.editor_bibliografico ? <div className="vs">
          <CopyField
            name="editor bibliogr&aacute;fico"
            value={ product.editor_bibliografico }
          />
        </div> : null }

        { product.traductor_bibliografico ? <div className="vs">
          <CopyField
            name="traductor bibliogr&aacute;fico"
            value={ product.traductor_bibliografico }
          />
        </div> : null }

      </div>

    </section>

    <footer>
      lorem
    </footer>
  </main>
}

export default withSharedStorage( Product )
