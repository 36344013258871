import { useState } from 'react'
import http from 'http'
import { withSharedStorage } from 'context/SharedStorage'

import { FiSave, FiX } from 'react-icons/fi'

const ContactForm = ( {
  site,
  close = () => {},
  refresh = () => {},
  withSharedStorage: { storage: { client } }
} ) => {
  const initModel = {
    email: '',
    priority: 0
  }
  const [ model, updateModel ] = useState( { ...initModel } )

  const handleChange = ( e ) => {
    const { name, value } = e.target

    updateModel( { ...model, [ name ]: value } )
  }

  const clear = () => {
    updateModel( { ...initModel } )
  }

  const cancel = () => {
    clear()
    close()
  }

  const create = ( e ) => {
    e.preventDefault()

    const contactList = [ ...site.contactList, model ]
    console.log( { model, contactList } )

    http
      .put( `/api/proxy/${ client.name }/contact/${ site._id }`, {
        contactList
      } )
      .then( ( { data } ) => {
        close()
        refresh()
      } )
  }

  return <form onSubmit={ create }>
    <div className="vs">
      <div className="two vs">
        <input
          className="one"
          name="email"
          type="email"
          placeholder="email"
          required
          value={ model.email }
          onChange={ handleChange }
          size={ 1 }
        />
      </div>

      <div className="one vs">
        <input
          className="one"
          name="priority"
          type="number"
          placeholder="priority"
          required
          value={ model.priority }
          onChange={ handleChange }
          size={ 1 }
        />
      </div>

      <div>
        <button type="submit" title="save">
          <FiSave />
        </button>

        <button type="button" title="cancel" onClick={ cancel }>
          <FiX />
        </button>
      </div>
    </div>
  </form>
}

export default withSharedStorage( ContactForm )
