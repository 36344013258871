import { withList } from 'context/List'
import LinkType from './LinkType'

const ControlsWrapper = ( {
  withList: { isToggled }
} ) => {
  if ( ! isToggled ) return null

  return <>
    <LinkType />
  </>
}

export default withList( ControlsWrapper )
