import { useState, useEffect } from 'react'
import { withSharedStorage } from '../../../context/SharedStorage'
import moment from 'moment'
import http from '../../../http'
import { InputText, Select } from '../../../components/Form'
import { Row } from '../../../components/Layout'

const ProductForm = ( {
  close = () => {},
  save = () => {},
  saveButtonLabel = 'guardar',
  init = {},
  withSharedStorage: { storage: { client } }
} ) => {
  const emptyModel = {
    obra_interno: '',
    identificador: '',
    titulo: '',
    subtitulo: '',
    autor_bibliografico: '',
    editor_bibliografico: '',
    traductor_bibliografico: '',
    tipo_obra: '',
    date_add: moment().startOf( 'day' ),
  }

  const [ model, setModel ] = useState( {} )

  useEffect( () => {
    setModel( { ...emptyModel, ...init } )
    console.log( { effect: model } )
  }, [ init ] )

  const updateModel = ( e ) => {
    const { name, value } = e.target

    setModel( { ...model, [ name ]: value } )
  }

  const localSave = ( e ) => {
    e.preventDefault()
    save( model )
  }

  return <form onSubmit={ localSave }
    className="hs gap"
  >
    <Row>
      <InputText
        name="obra_interno" 
        model={ model }
        updateModel={ updateModel }
        required={ true }
      />

      <InputText
        name="identificador" 
        model={ model }
        updateModel={ updateModel }
        required={ true }
        disabled={ true }
      />
    </Row>

    <Row>
      <InputText
        className="two"
        name="titulo" 
        model={ model }
        updateModel={ updateModel }
        required={ true }
      />

      <Select
        endpoint={ `/api/dataSource/${ client.name }/catalog/distinct` }
        params={ { field: 'tipo_obra' } }
        name="tipo_obra"
        label="tipo de obra"
        model={ model }
        updateModel={ updateModel }
        required={ true }
      />
    </Row>

    <Row>
      <InputText
        name="subtitulo" 
        model={ model }
        updateModel={ updateModel }
      />
    </Row>

    <Row>
      <InputText
        name="autor_bibliografico" 
        label="autor bibliogr&aacute;fico"
        model={ model }
        updateModel={ updateModel }
        required={ true }
      />
    </Row>

    <Row>
      <InputText
        name="editor_bibliografico" 
        label="editor bibliogr&aacute;fico"
        model={ model }
        updateModel={ updateModel }
        required={ true }
      />

      <InputText
        name="traductor_bibliografico" 
        label="traductor bibliogr&aacute;fico"
        model={ model }
        updateModel={ updateModel }
      />
    </Row>

    <Row
      className="reverse"
    >
      <button
        className="btn frm accent"
      >
        { saveButtonLabel }
      </button>
    </Row>
  </form>
}

export default withSharedStorage( ProductForm )
