import { withList } from '../../../context/List'

import Delete from './Delete'

function ControlsWrapper( props ) {
  const {
    client
  } = props

  const {
    isToggled
  } = props.withList

  if ( ! isToggled )
    return <></>

  return <>
    <Delete
      client={ client }
    />
  </>
}

export default withList( ControlsWrapper )
