import React, { createContext, useState } from 'react'

const ListContext = createContext()

const defaultScope = {
  list: new Set(),
  add: () => {},
  remove: () => {},
  clear: () => {},
  isToggled: false,
  toggle: () => {}
}

function List( props ) {
  const [ list, setList ] = useState( new Set() )
  const [ isToggled, setIsToggled ] = useState( false )

  function add( item ) {
    setList( prev => new Set( [ ...prev, item ] ) )
  }

  function remove( item ) {
    setList( prev => new Set( [ ...prev ].filter( x => x !== item ) ) )
  }

  function clear() {
    setList( new Set() )
  }

  function toggle() {
    setIsToggled( ! isToggled )
  }

  return (
    <ListContext.Provider
      value={ {
        list,
        add,
        remove,
        clear,
        isToggled,
        toggle
      } }
    >
      { props.children }
    </ListContext.Provider>
  )
}

const withList = ( ComponentAlias ) => {
  return props => (
    <ListContext.Consumer>
      { context => <ComponentAlias { ...props } withList={ context || defaultScope } /> }
    </ListContext.Consumer>
  )
}

export {
  withList,
  ListContext
}

export default List
