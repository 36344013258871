import transformations from 'transformations'

const Transform = ( {
  name,
  value,
  params = []
} ) => {
  const fn = transformations[ name ]

  return fn( value, ...params )
}

export default Transform
