import { ContactList } from '../_common'
import http from 'http'
import { withSharedStorage } from 'context/SharedStorage'
import { withReload } from 'context/Reload'
import MissingInformation from './_MissingInformation'

const NotifyByRow = ( {
  row,
  close,
  withSharedStorage: { storage: { client, schema } },
  withReload: { reload }
} ) => {
  console.log( row )

  // domain
  const { host: domain } = ( new URL( row.UrlDenuncia ) )

  const send = ( contact ) => {
    console.log( 'send', contact, row )
    http
      .post( `/api/proxy/${ client.name }/contact/sendEmailByItem`, {
        to: contact.email,
        schema: schema.name,
        item: row
      } )
      .then( ( { data } ) => {
        console.log( { sendEmail: data } )
        reload()
      } )
  }

  return <>
    <ContactList
      domain={ domain }
      send={ send }
      close={ close }
    />

    <MissingInformation
      row={ row }
      close={ close }
    />
  </>
}

export default withSharedStorage( withReload( NotifyByRow ) )
