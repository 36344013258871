import { useState, useEffect } from 'react'
import http from '../../http'
import join from '../../helpers/join'

const Select = ( {
  className = 'one',
  endpoint,
  params = {},
  type = 'text',
  name,
  label,
  placeholder,
  required,
  options = [],
  model,
  updateModel,
  onlyControl = false
} ) => {
  const [ opList, setOpList ] = useState( options )
  const fetched = false

  useEffect( () => {
    if ( ! endpoint ) return

    http
      .post( endpoint, params )
      .then( ( { data } ) => {
        setOpList( data )
      } )
  }, [ fetched ] )

  const control = <select
    className="one"
    name={ name }
    value={ model[ name ] }
    onChange={ updateModel }
    required={ required }
  >
    <option disabled value="">{ placeholder || label || name }</option>
    { opList.map( op => <option
      key={ op }
      value={ op }
    >
      { op }
    </option> ) }
  </select>

  if ( onlyControl )
    return control

  return <div className={ join( 'hs', className ) }>
    <div><label>{ label || name }</label></div>

    <div className="vs">
      { control }
    </div>

  </div>
}

export default Select
