import { useState, useEffect } from 'react'
import http from 'http'
import moment from 'moment'
import { withSharedStorage } from 'context/SharedStorage'
import { withReload } from 'context/Reload'

import FindBy from './_FindBy'

import {
  FiX,
  FiSend,
  FiTrash,
  FiSave
} from 'react-icons/fi'

import { MdOutlineMyLocation } from 'react-icons/md'

const ReportForm = ( {
  model,
  setModel,
  close,
  withSharedStorage: { storage: { client, schema } },
} ) => {
  // const [ model, setModel ] = useState( { ...row } )
  const [ isIsbnOpen, setIsIsbnOpen ] = useState( false )

  const send = ( e ) => {
    e.preventDefault()
  }

  const updateModel = ( e ) => {
    const { name, value } = e.target

    setModel( { ...model, [ name ]: value } )
  }

  const nextState = () => {
    const status = model[ 'EstadoDesc' ] || 'Found'

    let nextStatus = 'Notified'
    if ( status === 'Notified' )
      nextStatus = 'Deleted'

    let dateField = 'Date Sent'
    if ( nextStatus === 'Deleted' )
      dateField = 'Date Removed'

    setModel( {
      ...model, 
      EstadoDesc: nextStatus,
      [ dateField ]: moment().toISOString()
    } )
  }

  const identify = ( {
    identificador: Identificador,
    obra_interno: IdObraInterno,
    tipo_obra
  } ) => {
    setModel( {
      ...model,
      Identificador,
      IdObraInterno,
      'Tipo de obra': tipo_obra
    } )
  }

  const save = ( e ) => {
    e.preventDefault()

    http( {
      url: `/api/proxy/${ client.name }/collection/${ schema.name }/update`,
      method: 'PUT',
      data: model
    } )
      .then( ( { data } ) => {
        console.log( { data } )
      } )
  }

  return <main>
    <header>
      <h1>denuncia</h1>

      <div className="actions">
        <button onClick={ save }>
          <FiSave />
        </button>

        <button onClick={ close }>
          <FiX />
        </button>
      </div>
    </header>

    <section>
      <form className="hs gap padding" onSubmit={ save }>
        <div className="vs">
          <div className="hs one nogap">
            <label>_id</label>

            <div className="vs one">
              <input
                className="one"
                value={ model._id }
                disabled
                size={ 1 }
              />
            </div>
          </div>

          <div className="hs one nogap">
            <label>IdDenuncia</label>

            <div className="vs one nogap">
              <input
                className="one"
                name="IdDenuncia"
                value={ model.IdDenuncia }
                size={ 1 }
                onChange={ updateModel }
              />
            </div>
          </div>

        </div>

        <div className="vs">

          <div className="hs one nogap">
            <label>identificador</label>

            <div className="vs one nogap">
              <input
                className="one"
                name="Identificador"
                value={ model.Identificador }
                size={ 1 }
                onChange={ updateModel }
              />

              { /*
              <button
                title="identificar por ISBN"
                type="button"
                onClick={ searchByIsbn }
                id="identificador"
                name="Identificador"
                disabled={ [ null, '' ].indexOf( model.Identificador ) !== -1 }
              >
                <FiX />
              </button>
              */ }

              <FindBy
                fieldName="identificador"
                value={ model.Identificador }
                client={ client.name }
                updateModel={ identify }
              />
            </div>
          </div>

          <div className="hs one nogap">
            <label>IdObraInterno</label>

            <div className="vs one nogap">
              <input
                className="one"
                name="IdObraInterno"
                value={ model.IdObraInterno }
                size={ 1 }
                onChange={ updateModel }
              />

              { /*
              <button
                title="identificar por IdObraInterno"
                type="button"
                onClick={ searchByIsbn }
                id="obra_interno"
                name="IdObraInterno"
                disabled={ [ null, '' ].indexOf( model.IdObraInterno ) !== -1 }
              >
                <FiX />
              </button>
              */ }

              <FindBy
                fieldName="obra_interno"
                value={ model.IdObraInterno }
                client={ client.name }
                updateModel={ identify }
              />
            </div>
          </div>
        </div>

        <div className="vs">
          <div className="hs one nogap">
            <label>tipo de obra</label>

            <div className="vs one nogap">
              <select
                className="one"
                id="tipo_obra"
                name="Tipo de obra"
                value={ model[ 'Tipo de obra' ] }
                onChange={ updateModel }
                size={ 1 }
              >
                <option selected disabled>tipo de obra</option>
                { [
                  'Libro',
                  'Libro - Enseñanza',
                  'Partitura',
                  'Prensa',
                  'Revista',
                  'Url'
                ].map( tipo => ( <option
                  value={ tipo }
                >
                  { tipo }
                </option> ) ) }
              </select>

            </div>
          </div>

        </div>

        <div className="vs">
          <div className="hs one nogap">
            <label>UrlDenuncia</label>

            <div className="vs one nogap">
              <input
                className="one"
                name="UrlDenuncia"
                value={ model.UrlDenuncia }
                size={ 1 }
                onChange={ updateModel }
              />
            </div>
          </div>
        </div>

        <div className="vs">
          <div className="hs one nogap">
            <label>TipoEnlaceDesc</label>

            <div className="vs one nogap">
              <select
                className="one"
                name="TipoEnlaceDesc"
                value={ model.TipoEnlaceDesc }
                onChange={ updateModel }
                size={ 1 }
              >
                <option selected disabled>tipo enlace</option>
                { [
		    					'Comercio electrónico',
		    					'Sin archivo disponible',
		    					'Lectura en línea',
		    					'Mensajería instantanea',
		    					'Mobile app',
		    					'Motores de búsqueda',
		    					'Null',
		    					'Página almacén',
		    					'Página enlace',
		    					'Redes sociales',
		    					'Reproducción ilegal',
		    					'Torrent'
                ].map( tipo => ( <option
                  value={ tipo }
                >
                  { tipo }
                </option> ) ) }
              </select>
            </div>
          </div>
        </div>

        <div className="vs">
          <div className="hs two nogap">
            <label>EstadoDesc</label>

            <div className="vs one nogap">
              <input
                className="one"
                name="EstadoDesc"
                value={ model[ 'EstadoDesc' ] || 'Found' }
                size={ 1 }
                onChange={ updateModel }
		    				disabled
              />
            </div>
          </div>

          { ( model.EstadoDesc || 'Found' ) !== 'Deleted' ?
              <div className="hs one nogap">
              <label>&nbsp;</label>

              <button
                type="button"
                className="btn accent full"
                onClick={ nextState }
              >
                { ( model[ 'EstadoDesc' ] || 'Found' ) === 'Found' ?
                  'Notified' : 'Removed' }
              </button>
            </div>
          : null }
        </div>

        <div className="vs">

		    	<div className="horizontal-timeline one">

		    		<div className="timestamp">
		    			<div className="icon">
		    				<MdOutlineMyLocation />
		    			</div>

		    			<div className="time">
		    				<span>{ moment( model[ 'Date Found' ] ).format( 'MMM D, YYYY' ) }</span>
		    			</div>
		    		</div>

		    		{ [ 'Notified', 'Deleted' ].indexOf( model.EstadoDesc ) !== -1 ? (
		    			<div className="timestamp">
		    				<div className="icon">
		    					<FiSend />
		    				</div>

		    				<div className="time">
		    					<span>{ moment( model[ 'Date Sent' ] ).format( 'MMM D, YYYY' ) }</span>
		    				</div>
		    			</div>
		    		) : null }

		    		{ [ 'Deleted' ].indexOf( model.EstadoDesc ) !== -1 ? (
		    			<div className="timestamp">
		    				<div className="icon">
		    					<FiTrash />
		    				</div>

		    				<div className="time">
		    					<span>{ moment( model[ 'Date Removed' ] ).format( 'MMM D, YYYY' ) }</span>
		    				</div>
		    			</div>
		    		) : null }

		    	</div>

        </div>

        <div className="vs">
          <div className="hs one nogap">
            <label></label>

            <div className="vs one nogap">
            </div>
          </div>
        </div>

      </form>
    </section>
  </main>
}

export default withSharedStorage( ReportForm )
