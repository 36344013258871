import React, { useState, useEffect } from 'react'
import http from '../../http'
import DownloadButton from '../../components/DownloadButton'

import {
  FiDownload
} from 'react-icons/fi'

function Downloads( props ) {
  const [ clients, setClients ] = useState( [] )
  const [ collections, setCollections ] = useState( [
    {
      name: 'catalog'
    }
  ] )

  useEffect( () => {
    if ( clients.length !== 0 ) return
    http
      .get( '/api/collection/dataSource/all' )
      .then( response => {
        console.log( response.data )
        setClients( response.data )
      } )
  } )

  return <main>
    <header>
      <h1>Descargas</h1>
    </header>

    <section className="padding">
      { collections
        .map( collection => (
          <>
            <div className="title">
              <h1>{ collection.name }</h1>
            </div>

            <table>
              <thead>
                <tr>
                  <td>cliente</td>

                  <td className="actions x2"></td>
                </tr>
              </thead>

              <tbody>
                {
                  clients
                    .map( client => (
                      <tr>
                        <td>{ client.name }</td>

                        <td className="actions">
                          <DownloadButton
                            title="descargar"
                            endpoint={ `/api/dataSource/${ client.name }/${ collection.name }/export` }
                            prefix={ `${ client.name }-${ collection.name }-` }
                          >
                            <FiDownload />
                          </DownloadButton>
                        </td>
                      </tr>
                    ) )
                }
              </tbody>
            </table>
          </>
        ) ) }
    </section>
  </main>
}

export default Downloads
