import { useState } from 'react'
import moment from 'moment'
import { Row } from '../../../components/Layout'
import { InputText } from '../../../components/Form'
import http from '../../../http'
import { withAlert } from '../../../context/Alert'
import { withReload } from '../../../context/Reload'
import { withSharedStorage } from '../../../context/SharedStorage'
import { v4 as uuid } from 'uuid'

import { FiX } from 'react-icons/fi'

const CloneReport = ( {
  row,
  close,
  withAlert: { add: createAlert },
  withReload: { reload },
  withSharedStorage: { storage: { client, schema } }
} ) => {
  const [ model, setModel ] = useState( {
    ...row,
    'Date Found': moment().toDate(),
    UrlDenuncia: ''
  } )

  const save = ( e ) => {
    e.preventDefault()
    const { _id, ...rest } = model

    http
      .post( `/api/proxy/${ client.name }/collection/${ schema.name }/create`, {
        ...rest
      } )
      .then( ( { data } ) => {
        reload()
        close()
      } )
      .catch( err => {
        const { status } = err.response
        if ( status === 409 ) {
          return createAlert( {
            id: uuid(),
            timeout: 7,
            type: 'error',
            icon: 'FiAlertTriangle',
            fitle: 'duplicated',
            message: 'duplicated link'
          } )
        }

        console.log( err.response )
      } )
  }

  const updateModel = ( e ) => {
    const { name, value } = e.target

    setModel( { ...model, [ name ]: value } )
  }

  return <div className="modal x50">
    <main>
      <header>
        <h1>clone { row.IdDenuncia }</h1>

        <div className="actions">
          <button onClick={ close }>
            <FiX />
          </button>
        </div>
      </header>

      <section className="padding">
        <form onSubmit={ save } className="hs gap">
          <Row>
            <InputText
              name="UrlDenuncia"
              model={ model }
              updateModel={ updateModel }
              required={ true }
            />
          </Row>

          <Row
            className="vs reverse"
          >
            <button
              type="submit"
              className="btn frm accent"
            >
              guardar
            </button>
          </Row>
        </form>
      </section>
    </main>
  </div>
}

export default withSharedStorage( withReload( withAlert( CloneReport ) ) )
