import { useState } from 'react'
import { withReload } from '../../../context/Reload'
import moment from 'moment'
import http from '../../../http'
import { Row } from '../../../components/Layout'
import { InputText, Select } from '../../../components/Form'
import { FiX, FiSearch } from 'react-icons/fi'

import ProductListSelector from './_ProductListSelector'
import ProductForm from './_ProductForm'

const Reports = ( {
  client,
  schema,
  close = () => {},
  withReload: { reload }
} ) => {
  const [ model, setModel ] = useState( {
    IdDenuncia: '',
    IdObraInterno: '',
    Identificador: '',
    'Tipo de obra': '',
    UrlDenuncia: '',
    'Date Found': ''
  } )
  const [ isOnCatalog, setIsOnCatalog ] = useState( true )

  const updateModel = ( e ) => {
    const { name, value } = e.target

    setModel( { ...model, [ name ]: value } )
  }

  const save = ( e ) => {
    e.preventDefault()

    http
      .post( `/api/proxy/${ client.name }/collection/${ schema.name }/create`, {
        ...model,
        'Date Found': moment( model[ 'Date Found' ] ),
        'Date of Last Action': moment( model[ 'Date Found' ] )
      } )
      .then( ( { data: result } ) => {
        reload()
        close()
      } )
  }

  const findByInternalId = () => {
    const { Identificador } = model

    http
      .get( `/api/proxy/${ client.name }/product/check`, { params: {
        identificador: Identificador
      } } )
      .then( ( { data } ) => {
        setModel( {
          ...model,
          IdObraInterno: data.obra_interno,
          'Tipo de obra': data.tipo_obra
        } )
      } )
      .catch( err => {
        setIsOnCatalog( false )
      } )
  }

  const toggleProductForm = () => {
    setIsOnCatalog( ! isOnCatalog )
  }

  return <>
    { ! isOnCatalog ? <div className="modal x50">
      <main>
        <header>
          <h1>add product to catalog</h1>

          <div className="actions">
            <button 
              title="close"
              onClick={ close }
            >
              <FiX />
            </button>
          </div>
        </header>

        <section className="padding">
          <div className="hs gap">
            <p>
              el producto con identificador <strong>{ model.Identificador }</strong> no existe en nuestros registros, favor de completar la siguiente informaci&oacute;n.
            </p>

            <ProductForm
              client={ client }
              schema={ schema }
              identificador={ model.Identificador }
              close={ toggleProductForm }
            />
          </div>
        </section>
      </main>
    </div> : <div className="modal x70">
      <main>
        <header>
          <h1>create a new report</h1>

          <div className="actions">

            <button 
              title="close"
              onClick={ close }
            >
              <FiX />
            </button>
          </div>
        </header>

        <section className="padding">
          <form onSubmit={ save } className="hs gap">
            <Row>
              <InputText
                name="IdDenuncia" 
                model={ model }
                updateModel={ updateModel }
                required={ true }
              />
            </Row>

            <Row>
              <InputText
                name="Identificador" 
                model={ model }
                updateModel={ updateModel }
                required={ true }
              />

              <button
                className="btn square accent self-end"
                type="button"
                onClick={ findByInternalId }
              >
                <FiSearch />
              </button>

              <InputText
                name="IdObraInterno" 
                model={ model }
                updateModel={ updateModel }
                required={ true }
              />

              <Select
                endpoint={ `/api/proxy/${ client.name }/collection/${ schema.name }/enum` }
                params={ { field: 'Tipo de obra' } }
                name="Tipo de obra"
                label="tipo de obra"
                model={ model }
                updateModel={ updateModel }
                required={ true }
              />
            </Row>

            <Row>
              <InputText
                name="UrlDenuncia" 
                model={ model }
                updateModel={ updateModel }
                required={ true }
              />
            </Row>

            <Row>
              <InputText
                type="date"
                name="Date Found" 
                model={ model }
                updateModel={ updateModel }
                required={ true }
              />
            </Row>

            <Row
              className="reverse"
            >
              <button
                type="submit"
                className="btn frm accent"
              >
                guardar
              </button>
            </Row>
          </form>
        </section>
      </main>
    </div> }
  </>
}

export default withReload( Reports )
