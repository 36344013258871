import join from '../../helpers/join'

const Row = ( {
  className,
  children
} ) => {

  return <div className={ join( 'vs', className ) }>
    { children }
  </div>
}

export default Row
