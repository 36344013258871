import { withList } from 'context/List'
import { FiSquare } from 'react-icons/fi'

const ToggleControlsButton = ( {
  withList: { clear, toggle }
} ) => {

  const action = () => {
    clear()
    toggle()
  }

  return <>
    <button
      title="selection"
      onClick={ action }
    >
      <FiSquare />
    </button>
  </>
}

export default withList( ToggleControlsButton )
