import { useState, useEffect } from 'react'
import http from 'http'

import { FiX } from 'react-icons/fi'

const ProductList = ( {
  client,
  fieldName,
  value,
  updateModel,
  close
} ) => {
  const count = 0
  const [ list, setList ] = useState()

  useEffect( () => {
    http( {
      url: `/api/proxy/${ client }/collection/catalog/list`,
      method: 'POST',
      params: {
        all: true,
        __raw: true
      },
      data: {
        [ fieldName ]: value
      }
    } )
      .then( ( { data } ) => {
        setList( data )
      } )
  }, [ count ] )

  if ( ! list ) return null

  if ( list.length === 1 ) {
    updateModel( list[ 0 ] )
    close()
    return null
  } else {

    return list && list.length === 0 ? <div className="modal tiny important">
      <main>
        <header>
          <h1>error</h1>

          <div className="actions">
            <button onClick={ close }>
              <FiX />
            </button>
          </div>
        </header>

        <section>
          <div className="vs one middle centered">
            <div className="padding">
              <p>
      no existen elementos asociados al { fieldName } <strong>{ value }</strong>
              </p>
            </div>
          </div>
        </section>
      </main>
    </div> : <div className="modal x50 important">
      <main>
        <header>
          <h1>products</h1>

          <div className="actions">
            <button onClick={ close }>
              <FiX />
            </button>
          </div>
        </header>

        <section>
          LISTA
        </section>
      </main>
    </div>
  }
}

export default ProductList
