import { useState, useEffect, useMemo } from 'react'
import http from 'http'
import { withSharedStorage } from 'context/SharedStorage'
import ProductForm from '../_common/_ProductForm'

import { FiX, FiMinimize } from 'react-icons/fi'

const MissingProducts = ( {
  domain,
  close = () => {},
  withSharedStorage: { storage: { client, schema } }
} ) => {
  const [ missingList, setMissingList ] = useState( [] )
  const [ current, setCurrent ] = useState()
  const [ index, setIndex ] = useState( 0 )

  useEffect( () => {
    http
      .post( `/api/proxy/${ client.name }/contact/checkItemsByDomain`, {
        schemaName: schema.name,
        domain
      } )
      .then( ( { data } ) => {
        const { missingProducts } = data

        setMissingList( missingProducts )

        if ( missingProducts.length !== 0 )
          setCurrent( missingProducts[ index ] )

        // console.log( domain, missingProducts, schema )
      } )
  }, [ domain ] )

  useMemo( () => {
    setCurrent( missingList[ index ] )
  }, [ index ] )

  const next = () => {
    if ( ! hastNext() )
      return setMissingList( [] )

    setIndex( index + 1 )
  }

  const hastNext = () => {
    return missingList[ index + 1 ] ? true : false
  }

  const minimize = () => {
    setMissingList( [] )
  }

  const save = ( model ) => {
    http
      .post( `/api/proxy/${ client.name }/collection/catalog_aux/create`, { ...model } )
      .then( () => {
        next()
      } )
  }

  if ( missingList.length === 0 )
    return null

  return <div className="modal x50">
    <main>
      <header>
        <h1>missing items</h1>

        <div className="actions">
          <button
            title="minimize"
            onClick={ minimize }
          >
            <FiMinimize />
          </button>

          <button
            title="close"
            onClick={ close }
          >
            <FiX />
          </button>
        </div>
      </header>

      <section className="hs gap padding">
        <div>
          <p>Existen <strong>{ missingList.length }</strong> t&iacute;tulos faltantes bajo el dominio <strong>{ domain }</strong>. Para poder notificar necesitamos que proporciones la informaci&oacute;n referente a cada uno de ellos.</p>
        </div>

        <div className="vs reverse">
          <p>{ index + 1 } / { missingList.length } </p>
        </div>

        <ProductForm
          save={ save }
          saveButtonLabel="siguiente"
          init={ { ...current } }
        />

      </section>
    </main>
  </div>
}

export default withSharedStorage( MissingProducts )
