import Select from '../../components/Select'

import { FiCornerLeftUp } from 'react-icons/fi'

function Placeholder( props ) {
  const {
    client,
    onClientChange,
    clients
  } = props

  return <main>
    <header>
      <Select
        className="full"
        id="clientSelector"
        name="client"
        multiple={ false }
        value={ client }
        onChange={ onClientChange }
        placeholder="selecciona un cliente"
        options={ [
          ...( clients.map( c => c.name ) )
        ] }
      />
    </header>

    <section className="placeholder centered">
      <div className="vs">
        <FiCornerLeftUp />
        <label>selecciona un cliente</label>
      </div>
    </section>
  </main>
}

export default Placeholder
