import React, { useState, useEffect } from 'react'
import http from '../../http'
import Filter from '../../context/Filter'
import List from '../../context/List'
import Reload from '../../context/Reload'
import Table from './_Table'
import Columns from '../../context/Columns'
import { ColumnsSelector, ExportTable } from '../../components/Table'
import Filters from '../../components/Filters'
import Select from '../../components/Select'
import ReloadButton from '../../components/ReloadButton'
import Placeholder from './_Placeholder'

import Controls from './Controls'
import ToggleSelection from './_ToggleSelection'

import { FiFilter } from 'react-icons/fi'

function Links( props ) {
  const [ client, setClient ] = useState( '' )
  const [ clients, setClients ] = useState( [] )
  const [ isFiltersOpened, setIsFiltersOpened ] = useState( false )
  const {
    before,
    excludedColumns,
  } = props

  useEffect( () => {
    if ( clients.length !== 0 ) return
    http
      .get( '/api/collection/dataSource/all' )
      .then( response => {
        setClients( response.data )
      } )
  } )

  function toggleFilters() {
    setIsFiltersOpened( ! isFiltersOpened )
  }

  function onClientChange( e, toggle ) {
    setClient( e.target.value )
    toggle()
  }

  if ( client === '' ) {
    return <Placeholder
      clients={ clients }
      client={ client }
      onClientChange={ onClientChange }
    />
  }

  return (
    <Reload>
    <Filter>
      <Columns
        endpoint={ `/api/dataSource/${ client }/datos/columns` }
        exclude={ excludedColumns }
      >
        <List>
          <main>
            <header>
              <Select
                className="full"
                id="clientSelector"
                name="client"
                multiple={ false }
                value={ client }
                onChange={ onClientChange }
                placeholder="selecciona un cliente"
                options={ [
                  ...( clients.map( c => c.name ) )
                ] }
              />

              { client !== '' ? ( <div className="actions">

                <ToggleSelection />

                <ExportTable
                  action={ {
                    title: 'exportar tabla',
                    icon: 'HiOutlineDocumentReport',
                    endpoint: `/api/dataSource/${ client }/datos/export`,
                    useFilter: 1,
                    prefix: 'datos-',
                  } }
                />

                <button title="filtrar" onClick={ toggleFilters } >
                  <FiFilter />
                </button>

                <ReloadButton />

                <ColumnsSelector
                  action={ {
                    title: 'seleccionar columnas',
                    icon: 'FiColumns'
                  } }
                />
              </div> ) : null }
            </header>

            <section className="padding">

              <Controls
                client={ client }
              />

              <Table
                before={ before }
                client={ client }
              />
            </section>

            { isFiltersOpened ? <Filters
              close={ toggleFilters }
              prefix={ `/api/dataSource/${ client }/datos` }
              client={ client }
            /> : null }
          </main>
        </List>
      </Columns>
    </Filter>
    </Reload>
  )
}

export default Links
