import React, { useState } from 'react'
import http from '../../http'
import Fetch from '../../components/Fetch'
import { ColumnsContext } from '../../context/Columns'
import { withReload } from '../../context/Reload'
import T, { Pager } from '../../components/Table'

const Table = ( props ) => {
  const [ query, setQuery ] = useState( {} )
  const {
    before,
    client,
    withReload: { reload }
  } = props

  if ( ! client )
    return

  return (
        <ColumnsContext.Consumer>
          { context => ( <>
            <div className="hs one overflow-hidden">
              <Fetch
                type="table"
                endpoint={ `/api/dataSource/${ client }/datos/list` }
                before={ before }
                query={ query }
                useFilter={ true }
              >
                { ( data, update ) => (
                  <div className="hs card table full">
                    <T
                      data={ data.page }
                      columns={ context.columns }
                      prefix={ `/api/dataSource/${ client }/datos` }
                      actions={ [
                        {
                          icon: 'BiUpsideDown',
                          title: 'fake',
                          modal: 'Confirm',
                          message: 'do you want to fake this domain?',
                          buttons: [
                            {
                              icon: 'FiCheck',
                              label: 'yes',
                              type: 'yes',
                              action: ( it ) => {
                                http( {
                                  url: `/api/dataSource/${ client }/datos/fake`,
                                  method: 'PUT',
                                  data: {
                                    Plataforma: it.row.Plataforma,
                                    Dominio: it.row.Dominio,
                                    'Dominio archivo': it.row[ 'Dominio archivo' ]
                                  }
                                } )
                                  .then( response => {
                                    reload()
                                    it.close()
                                  } )
                              }
                            },
                            {
                              icon: 'FiX',
                              label: 'no',
                              type: 'no',
                              action: ( it ) => {
                                it.close()
                              }
                            }
                          ]
                        },
                        {
                          icon: 'BsCardChecklist',
                          title: 'whitelist',
                          modal: 'Confirm',
                          message: 'do you want to whitelist this domain?',
                          buttons: [
                            {
                              icon: 'FiCheck',
                              label: 'yes',
                              type: 'yes',
                              action: ( it ) => {
                                http( {
                                  url: `/api/dataSource/${ client }/datos/whitelist`,
                                  method: 'PUT',
                                  data: {
                                    Plataforma: it.row.Plataforma,
                                    Dominio: it.row.Dominio,
                                    'Dominio archivo': it.row[ 'Dominio archivo' ],
                                    'Enlace archivo': it.row[ 'Enlace archivo' ],
                                    ChannelUrl: it.row[ 'ChannelUrl' ]
                                  }
                                } )
                                  .then( response => {
                                    reload()
                                    it.close()
                                  } )
                              }
                            },
                            {
                              icon: 'FiX',
                              label: 'no',
                              type: 'no',
                              action: ( it ) => {
                                it.close()
                              }
                            }
                          ]
                        },
                        {
                          icon: 'FiEdit2',
                          title: 'edit',
                          modal: 'EditRow',
                          params: {
                            endpoint: `/api/dataSource/${ client }/datos/update`,
                            client,
                            prefix: `/api/dataSource/${ client }/datos`
                          }
                        },
                        {
                          icon: 'FiTrash',
                          title: 'delete',
                          modal: 'Confirm',
                          message: 'do you want to delete this row?',
                          buttons: [
                            {
                              icon: 'FiCheck',
                              label: 'yes',
                              type: 'yes',
                              action: ( it ) => {
                                http( {
                                  url: `/api/dataSource/${ client }/datos/delete`,
                                  method: 'DELETE',
                                  data: { _id: it.row._id }
                                } )
                                  .then( response => {
                                    reload()
                                    it.close()
                                  } )
                              }
                            },
                            {
                              icon: 'FiX',
                              label: 'no',
                              type: 'no',
                              action: ( it ) => {
                                it.close()
                              }
                            }
                          ]
                        }
                      ] }
                    />

                    { data.page.length !== 0 ? (
                      <Pager
                        data={ data }
                        updateParams={ setQuery }
                      />
                    ) : null }
                  </div>
                ) }
              </Fetch>
            </div>
          </> ) }
        </ColumnsContext.Consumer>
  )
}

export default withReload( Table )
