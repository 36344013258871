import React, { useState, useMemo } from 'react'
import Filter from '../../context/Filter'
import List from 'context/List'
import { withReload } from '../../context/Reload'
import { withSharedStorage } from '../../context/SharedStorage'
import Fetch from '../../components/Fetch'
import Columns, { ColumnsContext } from '../../context/Columns'
import T, { ColumnsSelector, Pager, ExportTable } from '../../components/Table'
import ReloadButton from '../../components/ReloadButton'
import Filters from './Filters'
import http from 'http'

import {
  FiFilter,
} from 'react-icons/fi'

const Table = ( {
  client,
  schema,
  withReload: { reload },
  withSharedStorage: { storage: { params }, updateStorege }
} ) => {
  const [ count ] = useState( 0 )
  const [ query, setQuery ] = useState( {} )
  const [ isFiltersOpened, setIsFiltersOpened ] = useState( false )

  useMemo( () => {
    updateStorege( { client, schema } )
  }, [ count ] )

  const PREFIX = `/api/dataSource/${ client.name }/${ schema.name }`

  function toggleFilters() {
    setIsFiltersOpened( ! isFiltersOpened )
  }

  if ( ! schema )
    return

  return (
    <List><Columns
      endpoint={ `${ PREFIX }/columns` }
    >
      <Filter>
        <div className="title">
          <h1>{ schema.name }</h1>

          <div className="actions">

            <ExportTable
              action={ {
                title: 'exportar tabla',
                icon: 'HiOutlineDocumentReport',
                endpoint: `${ PREFIX }/export`,
                useFilter: 1,
                prefix: `${ schema.name }-`
              } }
            />

            <button title="filtrar" onClick={ toggleFilters } >
              <FiFilter />
            </button>

            <ReloadButton />

            <ColumnsSelector
              action={ {
                title: 'seleccionar columnas',
                icon: 'FiColumns'
              } }
            />
          </div>
        </div>

        <ColumnsContext.Consumer>
          { context => ( <>
            <div className="hs one">
              <Fetch
                type="table"
                endpoint={ `${ PREFIX }/list` }
                query={ query }
                params={ params }
                useFilter={ true }
              >
                { ( data, update ) => (
                  <div className="hs card table">
                    <T
                      data={ data.page }
                      columns={ context.columns }
                      prefix={ PREFIX }
                      refresh={ reload }
                      actions={ [
                        {
                          icon: 'BsCardChecklist',
                          title: 'whitelist',
                          modal: 'Confirm',
                          message: 'do you want to whitelist this domain?',
                          buttons: [
                            {
                              icon: 'FiCheck',
                              label: 'yes',
                              type: 'yes',
                              action: ( it ) => {
                                http( {
                                  url: `/api/proxy/${ client.name }/sites/whitelist`,
                                  method: 'PUT',
                                  data: {
                                    _id: it.row._id
                                  }
                                } )
                                  .then( response => {
                                    reload()
                                    it.close()
                                  } )
                              }
                            },
                            {
                              icon: 'FiX',
                              label: 'no',
                              type: 'no',
                              action: ( it ) => {
                                it.close()
                              }
                            }
                          ]
                        }
                      ] }
                    />

                    { data.page.length !== 0 ? (
                      <Pager
                        data={ data }
                        updateParams={ setQuery }
                      />
                    ) : null }
                  </div>
                ) }
              </Fetch>
            </div>

            { isFiltersOpened ? <Filters
              close={ toggleFilters }
            /> : null }
          </> ) }
        </ColumnsContext.Consumer>
      </Filter>
    </Columns></List>
  )
}

export default withSharedStorage( withReload( Table ) )
