import { useState, useEffect } from 'react'
import http from 'http'
import qs from 'qs'
import { withSharedStorage } from 'context/SharedStorage'
import ProductForm from '../_common/_ProductForm'
import { FiX } from 'react-icons/fi'

const MissingInformation = ( {
  row,
  close,
  withSharedStorage: { storage: { client } }
} ) => {
  console.log( 'missing information', { row, close } )
  const [ isMissing, setIsMissing ] = useState( false )

  useEffect( () => {
    const params = {
      $or: [
        { identificador: row.Identificador },
        { obra_interno: row.IdObraInterno }
      ]
    }

    console.log( { params } )

    http
      .get( `/api/proxy/${ client.name }/product/check`, {
        params,
        paramsSerializer: params => {
          return qs.stringify( params )
        }
      } )
      .catch( err => {
        if ( err.response.status === 404 )
          setIsMissing( true )
      } )
  }, [ row ] )

  const hide = () => {
    console.log( 'hide' )
    setIsMissing( false )
  }

  const save = ( model ) => {
    http
      .post( `/api/proxy/${ client.name }/collection/catalog_aux/create`, { ...model } )
      .then( () => {
        hide()
      } )
  }

  if ( ! isMissing ) return null

  return <div className="modal x50">
    <main>
      <header>
        <h1>missing information</h1>

        <div className="actions">
          <button onClick={ close }>
            <FiX />
          </button>
        </div>
      </header>

      <section className="hs gap padding">
        <ProductForm
          save={ save }
          saveButtonLabel="guardar"
          init={ {
            identificador: row.Identificador
          } }
        />
      </section>
    </main>
  </div>
}

export default withSharedStorage( MissingInformation )
