import { useState } from 'react'
import Reports from './_Reports'
import ReportsChannel from './_ReportsChannel'

import { FiPlus } from 'react-icons/fi'

const Add = ( {
  client,
  schema
} ) => {
  const [ isFormOpened, setIsFormOpened ] = useState( false )

  const toggle = () => {
    setIsFormOpened( ! isFormOpened )
  }

  return <>
    { ! isFormOpened ? <button
      title="add"
      onClick={ toggle }
    >
      <FiPlus />
    </button> : schema.name === 'reports' ? <Reports
      client={ client }
      schema={ schema }
      close={ toggle }
    /> : <ReportsChannel
      client={ client }
      schema={ schema }
      close={ toggle }
    />
    }
  </>
}

export {
  Reports,
  ReportsChannel
}

export default Add
