import { useState } from 'react'
import Product from './_Product'
import ReportForm from './_ReportForm'

const EditReport = ( {
  row,
  close
} ) => {
  const [ model, setModel ] = useState( { ...row } )

  return <div className="modal full vs nogap">
    <Product
      model={ model }
      setModel={ setModel }
      close={ close }
    />

    <ReportForm
      model={ model }
      setModel={ setModel }
      close={ close }
    />

  </div>
}

export default EditReport
