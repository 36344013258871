import React, { useState } from 'react'
import Select from '../../../../components/Select'
import UpdateSelected from './_UpdateSelected'
import UpdateAll from './_UpdateAll'

function Status( props ) {
  const [ status, setStatus ] = useState( '' )
  const { client } = props

  function onStatusChange( e, toggle ) {
    setStatus( e.target.value )
    toggle()
  }

  return (
    <div className="vs inline-controls">
      <Select
        className="one full"
        placeholder="estado"
        name="Estado"
        value={ status }
        onChange={ onStatusChange }
        multiple={ false }
        options={ [
          'Pendiente',
          'Verificado',
          'Notificado',
          'Eliminado'
        ] }
      />

      <UpdateSelected
        client={ client }
        status={ status }
      />

      <UpdateAll
        client={ client }
        status={ status }
      />
    </div>
  )
}

export default Status
