const B0olean = ( {
  name,
  label,
  placeholder,
  identity,
  model,
  updateModel,
  ...rest
} ) => {

  const handleChange = ( e ) => {
    const { name, value } = e.target
    const v = JSON.parse( value )

    updateModel( name, v )
  }

  return <select
    name={ identity }
    value={ model[ identity ] }
    onChange={ handleChange }
  >
    <option disabled selected>{ placeholder || label || name }</option>
    <option>true</option>
    <option>false</option>
  </select>
}

export default B0olean
