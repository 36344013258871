import React, { useState } from 'react'
import http from '../../../../http'
import { withList } from '../../../../context/List'
import { withFilter } from '../../../../context/Filter'
import { withReload } from '../../../../context/Reload'
import Confirm from '../../../../components/Confirm'

function DeleteAll( props ) {
  const {
    client,
    withReload: { reload }
  } = props

  const [ isOpen, setIsOpen ] = useState( false )

  function toggle( e ) {
    setIsOpen( ! isOpen )
  }

  function action( e ) {
    http( {
      url: `/api/dataSource/${ client }/datos/findAndDelete`,
      method: 'delete',
      data: {
        steps: props.withFilter.state.steps || []
      }
    } )
      .then( response => {
        reload()
      } )
  }

  return (
    <button
      className="btn danger one"
      onClick={ toggle }
    >
      delete all records

      { isOpen ?
        <Confirm
          icon="FiTrash"
          message="do you want to delete all filtered records?"
          buttons={ [
            {
              icon: 'FiCheck',
              label: 'yes',
              type: 'yes',
              action
            },
            {
              icon: 'FiX',
              label: 'no',
              type: 'no',
              action: () => { toggle() }
            }
          ] }
          close={ toggle }
        />
      : null }
    </button>
  )
}

export default withReload( withFilter( withList( DeleteAll ) ) )
