import { useState } from 'react'
import http from 'http'
import { withFilter } from 'context/Filter'
import { withSharedStorage } from 'context/SharedStorage'
import { withReload } from 'context/Reload'
import Confirm from 'components/Confirm'

const UpdateAll = ( {
  value,
  withFilter: { state: { steps = [] } },
  withSharedStorage: { storage: { client, schema, params } },
  withReload: { reload }
} ) => {
  const [ isOpen, setIsOpen ] = useState( false )

  const toggle = ( e ) => {
    setIsOpen( ! isOpen )
  }

  const action = () => {
    http( {
      url: `/api/proxy/${ client.name }/collection/${ schema.name }/findAndUpdate`,
      method: 'PUT',
      data: {
        steps: [ ...steps, params ],
        set: value
      }
    } )
      .then( response => {
        reload()
      } )
  }

  return <button
    className="btn danger"
    onClick={ toggle }
    disabled={ value === '' }
  >
    update all filtered items

    { isOpen ? <Confirm
      icon="FiGitCommit"
      message="do you want to update all filtered items?"
      buttons={ [
        {
          icon: 'FiCheck',
          label: 'yes',
          type: 'yes',
          action
        },
        {
          icon: 'FiX',
          label: 'no',
          type: 'no',
          action: () => { toggle() }
        }
      ] }
      close={ toggle }
    /> : null }
  </button>
}

export default withReload( withSharedStorage( withFilter( UpdateAll ) ) )
