import React, { useState } from 'react'
import http from '../../../../http'
import { withList } from '../../../../context/List'
import { withReload } from '../../../../context/Reload'
import moment from 'moment'
import Confirm from '../../../../components/Confirm'

function UpdateSelected( props ) {
  const {
    client,
    status,
    withReload: { reload }
  } = props

  const {
    list,
    clear
  } = props.withList

  const [ isOpen, setIsOpen ] = useState( false )

  function toggle( e ) {
    setIsOpen( ! isOpen )
  }

  function action() {
    // status
    let set = { Estado: status }
    switch ( status ) {
      case 'Notificado':
        let notifiedDateKeyName = 'Fecha notificado'
        if ( client === 'helvex' )
          notifiedDateKeyName = 'FechaNotificado'

        set = {
          ...set,
          [ notifiedDateKeyName ]: moment().toISOString()
        }
        break
      case 'Eliminado':
        let deletedDateKeyName = 'Fecha eliminado'
        if ( client === 'helvex' )
          deletedDateKeyName = 'FechaEliminado'

        set = {
          ...set,
          [ deletedDateKeyName ]: moment().toISOString()
        }
        break
    }

    http( {
      url: `/api/dataSource/${ client }/datos/updateMany`,
      method: 'PUT',
      data: {
        match: { _id: { $in: [ ...list ] } },
        set
      }
    } )
      .then( response => {
        clear()
        reload()
      } )
  }

  return (
    <button
      className="btn accent"
      onClick={ toggle }
      disabled={ status === '' || list.size === 0 }
    >
      update selected records { list.size > 0 ? `(${ list.size })` : '' }

      { isOpen ?
        <Confirm
          icon="FiGitCommit"
          message="do you want to update all selected records?"
          buttons={ [
            {
              icon: 'FiCheck',
              label: 'yes',
              type: 'yes',
              action
            },
            {
              icon: 'FiX',
              label: 'no',
              type: 'no',
              action: () => { toggle() }
            }
          ] }
          close={ toggle }
        />
      : null }
    </button>
  )
}

export default withReload( withList( UpdateSelected ) )
