import GeneralReport from '../components/GeneralReport'
import EditRow from '../components/EditRow'
import Confirm from '../components/Confirm'

import NotifyByRow from '../routes/Reports/NotifyByRow'
import NotifyByDomain from '../routes/Reports/NotifyByDomain'
import CloneReport from '../routes/Reports/CloneReport'
import RejectByRow from '../routes/Reports/RejectByRow'
import EditReport from '../routes/Reports/EditReport'

const modals = {
  GeneralReport,
  EditRow,
  Confirm,
  NotifyByRow,
  NotifyByDomain,
  CloneReport,
  RejectByRow,
  EditReport
}

export default modals
