const toInt = ( v ) => {
  return v ? 1 : 0
}

const toBool = ( v ) => {
  return v === 1 || v === '1' ? true : false
}

const buildEndpoint = ( url, prefix ) => {
  let ep = url

  if ( ! prefix )
    return url

  const pa = ep.match( /.*\/([^\/]+)$/ )
  const ac = pa.pop()
  return `${ prefix }/${ ac }`
}

export {
  toInt,
  toBool,
  buildEndpoint
}
