import { useState } from 'react'
import ProductList from './_ProductList'

import {
  FiSearch
} from 'react-icons/fi'

const FindBy = ( {
  fieldName,
  value,
  updateModel,
  client
} ) => {
  const [ isOpen, setIsOpen ] = useState( false )

  const toggle = () => {
    setIsOpen( ! isOpen )
  }

  if ( ! value ) return null

  return <>
    <button
      title={ `identificar por ${ fieldName }` }
      type="button"
      onClick={ toggle }
    >
      <FiSearch />
    </button>


    { isOpen ? <ProductList
      fieldName={ fieldName }
      value={ value }
      close={ toggle }
      client={ client }
      updateModel={ updateModel }
    /> : null }
  </>
}

export default FindBy
