import React, { useState } from 'react'
import http from '../../../../http'
import { withList } from '../../../../context/List'
import { withReload } from '../../../../context/Reload'
import Confirm from '../../../../components/Confirm'

function UpdateSelected( props ) {
  const {
    client,
    withReload: { reload }
  } = props

  const {
    list,
    clear
  } = props.withList

  const [ isOpen, setIsOpen ] = useState( false )

  function toggle( e ) {
    setIsOpen( ! isOpen )
  }

  function action() {
    http( {
      url: `/api/dataSource/${ client }/whitelist/deleteMany`,
      method: 'delete',
      data: {
        match: { _id: { $in: [ ...list ] } }
      }
    } )
      .then( response => {
        clear()
        reload()
      } )
  }

  return (
    <button
      className="btn accent one"
      onClick={ toggle }
      disabled={ list.size === 0 }
    >
      delete selected records {
        list.size > 0
        ?  `(${ list.size })`
        : ''
      }

      { isOpen ?
        <Confirm
          icon="FiTrash"
          message="do you want to delete all selected records?"
          buttons={ [
            {
              icon: 'FiCheck',
              label: 'yes',
              type: 'yes',
              action
            },
            {
              icon: 'FiX',
              label: 'no',
              type: 'no',
              action: () => { toggle() }
            }
          ] }
        />
      : null }
    </button>
  )
}

export default withReload( withList( UpdateSelected ) )
