import { withList } from '../../context/List'

import { FiSquare } from 'react-icons/fi'

function ToggleSelection( props ) {
  const {
    clear,
    toggle
  } = props.withList

  function action() {
    clear()
    toggle()
  }

  return <button
    title="activar selecci&oacute;n"
    onClick={ action }
  >
    <FiSquare />
  </button>
}

export default withList( ToggleSelection )
