import React, { useState, useRef } from 'react'
import http from '../http'
import { withProfile } from '../context/Profile'

function Autocomplete( props ) {
  const [ options, setOptions ] = useState( [] )
  const [ index, setIndex ] = useState( -1 )
  const [ localValue, setLocaValue ] = useState( props.value )
  const [ isActive, setIsActive ] = useState( false )
  const [ fetchTimeout, setFetchTimeout ] = useState()
  const inputElem = useRef( null )

  function fetch( value ) {
    // prevent fetch
    if ( ! isActive ) return

    if ( ! value || value === '' ) {
      setValue( '', true )
      return clear()
    }

    const params = JSON.parse (
      JSON.stringify( props.__external )
        .replace( '"VALUE"', `"${ value }"` )
    )

    let body = [
      { __external: params }
    ]

    // add params
    const { __select: s } = props
    if ( s && s.length > 0 ) {
      body = [
        {
          __select: s
        },
        ...body
      ]
    }

    http( {
      url: props.endpoint,
      method: 'POST',
      params: {
        key: props.k,
        caption: props.c
      },
      data: body,
      silence: true
    } )
      .then( res => {
        setIndex( -1 )
        setOptions( res.data )
      } )
  }

  function navigate( event ) {
    if ( [ 13, 38, 40 ].indexOf( event.keyCode ) !== -1 )
      event.preventDefault()

    switch ( event.keyCode ) {
      case 40: // down
        if ( index < options.length - 1 )
          setIndex( index + 1 )
        else
          setIndex( 0 )
        break
      case 38: // up
        if ( index > 0 )
          setIndex( index -1 )
        else
          setIndex( options.length - 1 )
        break
      case 13: // enter
        const option = options[ index ]

        if ( option )
          setValue( option )

        clear()
        break
    }
  }

  function blur( event ) {
    const target = event.nativeEvent.explicitOriginalTarget

    let wrapper = target.parentElement.parentElement
    if ( target.nodeName !== 'LI' )
      wrapper = target.parentElement.parentElement.parentElement

    if ( wrapper.className === 'options' )
      return

    clear()
  }

  function clear( event ) {
    setOptions( [] )
    setIndex( -1 )
  }

  function setValue( value, enabled = false ) {
    let val = value

    if ( typeof val !== 'string' )
      val = val[ props.k ]

    setLocaValue( val )

    updateModelValue( {
      id: props.id,
      name: props.name,
      value: val
    } )

    const { __target: t } = props
    if ( t ) {
      updateModelValue( {
        id: t.id,
        name: t.name,
        value: value[ t.name ]
      } )
    }

    setIsActive( enabled )
    clear()
  }

  function updateValue( e ) {
    const { value } = e.target

    setLocaValue( value )

    if ( fetchTimeout ) {
      clearTimeout( fetchTimeout )
      setFetchTimeout( null )
    }

    const timeout = setTimeout( () => fetch( value ), 750, localValue )

    setFetchTimeout( timeout )

    updateModelValue( {
      id: props.id,
      name: props.name,
      value: value
    } )
  }

  function updateModelValue( target ) {
    props.onSelect( { target } )
  }

  return (
    <div className="autocomplete">
      <input
        ref={ inputElem }
        type="text"
        name={ props.name }
        value={ localValue }
        size="1"
        placeholder={ props.placeholder }
        onKeyDown={ navigate }
        onChange={ updateValue }
        onBlur={ blur }
        onFocus={ () => setIsActive( true ) }
      />

      <div className="options">
        <ul>
          { options.map( ( el, i ) => (
            <li
              className={ i === index ? 'selected' : '' }
              onMouseEnter={ () => setIndex( i ) }
              onClick={ () => setValue( el ) }
            >
              { typeof el === 'string' ? el : el.__caption ? el.__caption : el[ props.k ] }
            </li>
          ) ) }
        </ul>
      </div>
    </div>
  )
}

export default withProfile( Autocomplete )
