import { useState, useMemo } from 'react'
import Transform from 'components/Transform'
import moment from 'moment'
import http from 'http'

import { buildEndpoint } from './helpers'

import { FiX } from 'react-icons/fi'

const Input = ( {
  value,
  options = [],
  update,
  close = () => {}
} ) => {
  return <>
    <select
      className="one"
      value={ value }
      onChange={ update }
    >
      { options
        .map( op => op.label || op )
        .map( ( op, i ) => <option
        key={ i }
      >
        { op }
      </option> ) }
    </select>

    <button onClick={ close }>
      <FiX />
    </button>
  </>
}

const translateOptions = ( options ) => {
  return options.map( ( op ) => ( {
    label: op.label || op,
    value: op.value || op,
    modal: op.modal || undefined
  } ) )
}

const Select = ( {
  identity,
  prefix,
  endpoint,
  name,
  value,
  options,
  enum: _enum,
  refresh = () => {},
  ...rest
} ) => {
  const [ isEditable, setIsEditable ] = useState( false )
  const [ ops, setOps ] = useState( [] )

  useMemo( () => {
    if ( options && options.length > 0 )
      return setOps( translateOptions( options ) )

    setOps( translateOptions( _enum ) )
  }, [
    options,
    _enum
  ] )

  const toggle = () => {
    setIsEditable( ! isEditable )
  }

  const update = ( e ) => {
    const { value: localValue } = e.target

    const item = ops.find( o => o.label === localValue )
    if ( ! item ) return

    let { value: data } = item
    if ( data.indexOf( '__NOW__' ) !== -1 )
      data = data.replaceAll( '"__NOW__"', JSON.stringify( moment().toISOString() ) )

    try {
      data = { ...JSON.parse( data ), _id: identity }
    } catch ( err ) {
      data = { _id: identity, [ name ]: data }
    }

    const url = buildEndpoint( endpoint, prefix )

    http
      .put( url, data )
      .then( ( { data } ) => {
        toggle()
        refresh()
      } )
  }

  let content = value

  if ( rest.transform ) {
    content = <Transform
      name={ rest.transform }
      value={ value }
      params={ rest.param }
    />
  }

  return <>
    { ! isEditable ?
      <button className="one text-align-left" onClick={ toggle }>
        { content }
      </button>
    :
      <Input
        value={ value }
        options={ ops }
        update={ update }
        close={ toggle }
      />
    }
  </>
}

export default Select
