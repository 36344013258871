import { createContext, useState } from 'react'

const ReloadContext = createContext()

const Reload = ( {
  children
} ) => {
  const [ reloadCount, updateCount ] = useState( 0 )

  const reload = () => {
    updateCount( reloadCount + 1 )
  }

  return <ReloadContext.Provider
    value={ {
      reloadCount,
      reload
    } }
  >
    { children }
  </ReloadContext.Provider>
}

const withReload = ( ComponentAlias ) => props => <ReloadContext.Consumer>{ context => <ComponentAlias { ...props } withReload={ context } /> }</ReloadContext.Consumer>

export {
  ReloadContext,
  withReload
}

export default Reload
