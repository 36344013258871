import { FiArrowLeft } from 'react-icons/fi'

const MainPlaceholder = () => {

  return <main>
    <section className="placeholder centered">
      <div className="vs">
        <FiArrowLeft />
        <label>selecciona una secci&oacute;n del menu izquierdo</label>
      </div>
    </section>
  </main>
}

export default MainPlaceholder
