import { withReload } from '../context/Reload'
import { FiRefreshCcw } from 'react-icons/fi'

const ReloadButton = ( {
  withReload: { reload }
} ) => {

  return <button title="actualizar" onClick={ reload }>
    <FiRefreshCcw />
  </button>
}

export default withReload( ReloadButton )
