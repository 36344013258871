import { useState, useEffect } from 'react'
import http from 'http'
import { withSharedStorage } from 'context/SharedStorage'
import { withReload } from 'context/Reload'

import { FiX } from 'react-icons/fi'

const NotifyByRow = ( {
  row,
  close,
  withSharedStorage: { storage: { client, schema } },
  withReload: { reload }
} ) => {
  const count = 0
  const [ model, setModel ] = useState( {
    ...row,
    ExcelRechazado: 'Si',
    EstadoDesc: 'Rejected'
  } )

  const submit = ( e ) => {
    e.preventDefault()

    http
      .put( `/api/proxy/${ client.name }/collection/${ schema.name }/update`, {
        ...model
      } )
      .then( ( { data } ) => {
        reload()
        close()
      } )

  }

  const handleChange = ( e ) => {
    const { name, value } = e.target

    setModel( { ...model, [ name ]: value } )
  }

  return <div className="modal x50">
    <main>
      <header>
        <h1>reject</h1>

        <div className="actions">
          <button onClick={ close }>
            <FiX />
          </button>
        </div>
      </header>

      <section className="hs gap padding">

        <form onSubmit={ submit } className="hs">
          <RenderRowAsForm
            client={ client.name }
            schema={ schema.name }
            model={ model }
            handleChange={ handleChange }
          />

          <div className="vs reverse">
            <button
              type="submit"
              className="btn frm accent"
            >
              reject
            </button>

            <button
              type="reset"
              className="btn frm disabled"
              onClick={ close }
            >
              cancel
            </button>
          </div>
        </form>
      </section>
    </main>
  </div>
}

const RenderRowAsForm = ( {
  client,
  schema,
  model,
  handleChange
} ) => {
  const count = 0
  const [ columns, setColumns ] = useState( [] )

  const frm = [
    [
      {
        name: '_id',
        type: 'string',
        readOnly: true
      },
      {
        name: 'IdDenuncia',
        type: 'string',
        readOnly: true
      }
    ],
    [
      {
        name: 'IdObraInterno',
        type: 'string',
        readOnly: true,
      },
      {
        name: 'Identificador',
        type: 'string',
        readOnly: true,
      }
    ],
    [
      {
        name: 'Tipo de obra',
        type: 'string',
        readOnly: true,
        enum: [
          'Libro',
          'Libro - Enseñanza',
          'Partitura',
          'Prensa',
          'Revista',
          'Url'
        ]
      },
      {
        name: 'EstadoDesc',
        type: 'string',
        readOnly: true,
        enum: [
          'Deleted',
          'Notified',
          'Rejected'
        ]
      },
      {
        name: 'TipoEnlaceDesc',
        type: 'string',
        enum: [
          'Comercio electrónico',
          'Sin archivo disponible',
          'Lectura en línea',
          'Mensajería instantanea',
          'Mobile app',
          'Motores de búsqueda',
          'Null',
          'Página almacén',
          'Página enlace',
          'Redes sociales',
          'Reproducción ilegal',
          'Torrent'
        ],
        required: true
      }
    ],
    [
      {
        name: 'UrlDenuncia',
        type: 'string'
      },
    ],
    [
      {
        name: 'ExcelMotivo Rechazo',
        type: 'string',
        enum: [
          'Enlace raíz',
          'no previsto',
          'Sin contenido a denunciar',
          'Pag. no responde',
          'Página legal',
          'Información parcial'
        ],
        required: true
      }
    ]
  ]

  useEffect( () => {
    http
      .get( `/api/proxy/${ client }/collection/${ schema }/columns` )
      .then( ( { data } ) => {
        setColumns( data )
      } )
  }, [ count ] )

  const buildRow = ( r ) => {
    return <div className="vs">
      { r.map( buildCol ) }
    </div>
  }

  const buildCol = ( c ) => {

    return <div className="hs one nogap">
      <div className="one">
        <strong>{ c.name }</strong>
      </div>

      <div className="one vs">
        { c.type === 'string' && ! c.enum ?
          <input
            className="one"
            type="text"
            name={ c.name }
            value={ model[ c.name ] }
            disabled={ c.readOnly }
            size={ 1 }
            onChange={ handleChange }
            required={ c.required }
          />
        : null }

        { c.enum ?
          <select className="one"
            name={ c.name }
            value={ model[ c.name ] }
            defaultValue=""
            disabled={ c.readOnly }
            onChange={ handleChange }
            required={ c.required }
          >
            <option value="" disabled>{ c.name }</option>
            { c.enum.map( o => <option>
              { o }
            </option> ) }
          </select>
        : null }
      </div>
    </div>
  }

  return <>
    { frm.map( buildRow ) }
  </>
}

export default withSharedStorage( withReload( NotifyByRow ) )
