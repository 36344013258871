import { useState, Component } from 'react'
import moment from 'moment'
import http from 'http'
import Transform from 'components/Transform'

import { toInt, toBool, buildEndpoint } from './helpers'

import { FiX } from 'react-icons/fi'

const Input = ( {
  value: initialValue,
  update = () => {},
  close = () => {}
} ) => {
  const [ value, setValue ] = useState( toInt( initialValue ) )

  return <>
    <input
      className="one"
      style={ { width: "7px" } }
      type="range"
      min={ 0 }
      max={ 1 }
      value={ value }
      size={ 1 }
      onChange={ update }
    />

    <button onClick={ close }>
      <FiX />
    </button>
  </>
}

const B0olean = ( {
  identity,
  prefix,
  endpoint,
  name,
  value = false,
  options,
  refresh = () => {},
  ...rest
} ) => {
  const [ isEditable, setIsEditable ] = useState( false )

  const toggle = () => {
    setIsEditable( ! isEditable )
  }

  const update = ( e ) => {
    const { value: v } = e.target
    const val = toBool( v )
    const url = buildEndpoint( endpoint, prefix )

    let data = {
      _id: identity,
      [ name ]: val
    }

    if ( options && options.length === 2 ) {
      let item = options[ val ? 1 : 0 ]

      if ( item.indexOf( '__NOW__' ) !== -1 )
        item = item.replaceAll( '"__NOW__"', JSON.stringify( moment().toISOString() ) )

      try {
        data = { ...data, ...JSON.parse( item ) }
      } catch ( err ) {
        data = { _id: identity, [ name ]: val }
      }

    }

    http
      .put( url, data )
      .then( ( { data } ) => {
        toggle()
        refresh()
      } )
  }

  // parse content
  let content = value.toString()

  if ( rest.transform ) {
    content = <Transform
      name={ rest.transform }
      value={ value }
      params={ rest.params }
    />
  }

  return <>
    { ! isEditable ?
      <button onClick={ toggle } >
        { content }
      </button>
    :
      <Input
        value={ value }
        update={ update }
        close={ toggle }
      />
    }
  </>
}

export default B0olean
