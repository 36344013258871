import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Skin from './context/Skin'
import Alert from './context/Alert'
import AlertList from './components/AlertList'
import Blank from './components/Blank'
import App from './App'
import Redirect from './components/Redirect'
import { Login } from './routes/auth'
import Board from './routes/Board'
import Details from './routes/Details'
import WhiteList from './routes/Details/_WhiteList'
import Downloads from './routes/Downloads'
import Reports from './routes/Reports'
import Sites from './routes/Sites'
import MainPlaceholder from './components/MainPlaceholder'
import reportWebVitals from './reportWebVitals'
import './style/style.sass'

const rootElement = document.getElementById( 'root' )

ReactDOM.render(
  <Skin endpoint="/api/collection/skin/first">
    <Alert>
      <AlertList />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <App /> }>

            <Route
              key="searchEngine"
              path="searchEngine"
              element={ <Details
                key={ `searchEngine${ Date.now() }` }
                before={ [
                  {
                    Plataforma: 'Search engine',
                    'Tipo de pagina': { $ne: 'Fake' },
                    WhiteList: { $ne: 1 }
                  }
                ] }
                excludedColumns={ [
                  'Plataforma'
                ] }
              /> }
            />

            <Route
              key="streamingPlatforms"
              path="streamingPlatforms"
              element={ <Details
                key={ `streamingPlatforms${ Date.now() }` }
                before={ [
                  {
                    Plataforma: 'Video',
                    'Tipo de pagina': { $ne: 'Fake' },
                    WhiteList: { $ne: 1 }
                  }
                ] }
                excludedColumns={ [
                  'Plataforma'
                ] }
              /> }
            />

            <Route
              key="webSiteCyberlockers"
              path="webSiteCyberlockers"
              element={ <Details
                key={ Date.now() }
                key={ `webSiteCyberlockers${ Date.now() }` }
                before={ [
                  {
                    Plataforma: 'Website',
                    'Tipo de pagina': { $ne: 'Fake' },
                    WhiteList: { $ne: 1 }
                  }
                ] }
                excludedColumns={ [
                  'Plataforma'
                ] }
              /> }
            />

            <Route
              key="socialMedia"
              path="socialMedia"
              element={ <Details
                key={ `socialMedia${ Date.now() }` }
                before={ [
                  {
                    Plataforma: 'Redes sociales',
                    'Tipo de pagina': { $ne: 'Fake' },
                    WhiteList: { $ne: 1 }
                  }
                ] }
                excludedColumns={ [
                  'Plataforma'
                ] }
              /> }
            />

            <Route
              key="eCommerce"
              path="eCommerce"
              element={ <Details
                key={ `eCommerce${ Date.now() }` }
                before={ [
                  {
                    Plataforma: 'Comercio electrónico',
                    'Tipo de pagina': { $ne: 'Fake' },
                    WhiteList: { $ne: 1 }
                  }
                ] }
                excludedColumns={ [
                  'Plataforma'
                ] }
              /> }
            />

            <Route
              key="complaints"
              path="complaints"
              element={ <Details
                key={ `complaints${ Date.now() }` }
                before={ [
                  {
                    Plataforma: 'Denuncia',
                    'Tipo de pagina': { $ne: 'Fake' },
                    WhiteList: { $ne: 1 }
                  }
                ] }
                excludedColumns={ [
                  'Plataforma'
                ] }
              /> }
            />

            <Route
              key="whiteList"
              path="whiteList"
              element={ <WhiteList
                key={ `whitelist${ Date.now() }` }
              /> }
            />

            <Route
              key="downloads"
              path="downloads"
              element={ <Downloads /> }
            />

            <Route
              key="reports"
              path="reports"
              element={ <Reports /> }
            />

            <Route
              key="sites"
              path="sites"
              element={ <Sites /> }
            />

            <Route
              index
              element={ <MainPlaceholder /> }
            />

            <Route
              path="*"
              element={ <Redirect to="/" /> }
            />
          </Route>

          <Route path="auth" element={ <Blank /> }>
            <Route path="login" element={ <Login /> } />
          </Route>
        </Routes>
      </BrowserRouter>
    </Alert>
  </Skin>,
  rootElement
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
