import join from '../../helpers/join'

const InputText = ( {
  className = 'one',
  type = 'text',
  name,
  label,
  placeholder,
  required,
  model,
  updateModel,
  disabled = false
} ) => {

  return <div className={ join( 'hs', className ) }>
    <div><label>{ label || name }</label></div>

    <div className="vs">
      <input
        className="one"
        type={ type }
        name={ name }
        placeholder={ placeholder || label || name }
        value={ model[ name ] }
        onChange={ updateModel }
        required={ required }
        size={ 1 }
        disabled={ disabled }
      />
    </div>
  </div>
}

export default InputText
