import http from 'http'
import { withSharedStorage } from 'context/SharedStorage'
import { withReload } from 'context/Reload'
import { withAlert } from 'context/Alert'
import MissingProducts from './_MissingProducts'
import { ContactList } from '../_common'

const NotifyByDomain = ( {
  row,
  close,
  withSharedStorage: { storage: { client, schema } },
  withReload: { reload },
  withAlert: { add: createAlert },
} ) => {
  // domain
  const { host: domain } = ( new URL( row.UrlDenuncia ) )

  const send = ( contact, __force = false ) => {
    http
      .post( `/api/proxy/${ client.name }/contact/sendEmailByDomain`, {
        schema: schema.name,
        domain,
        to: contact.email,
        __force
      } )
      .then( ( { data } ) => {
        createAlert( {
          type: 'success',
          icon: 'FiCheck',
          title: 'sent'
        } )
        reload()
      } )
      .catch( ( err ) => {
        const { data } = err.response
        const { message } = data

        createAlert( {
          type: 'error',
          icon: 'FiLock',
          title: 'error',
          message
        } )
      } )
  }

  return <>
    <ContactList
      domain={ domain }
      send={ send }
      close={ close }
      force={ true }
    />

    <MissingProducts
      domain={ domain }
      close={ close }
    />
  </>
}

export default withSharedStorage( withReload( withAlert( NotifyByDomain ) ) )
