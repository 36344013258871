import React, { useState } from 'react'
import http from '../../../../http'
import { withFilter } from '../../../../context/Filter'
import { withList } from '../../../../context/List'
import { withReload } from '../../../../context/Reload'
import moment from 'moment'
import Confirm from '../../../../components/Confirm'

function UpdateAll( props ) {
  const {
    client,
    status,
    withReload: { reload }
  } = props

  const [ isOpen, setIsOpen ] = useState( false )

  function toggle( e ) {
    setIsOpen( ! isOpen )
  }

  function action( e ) {
    // status
    let set = { Estado: status }
    switch ( status ) {
      case 'Notificado':
        let notifiedDateKeyName = 'Fecha notificado'
        if ( client === 'helvex' )
          notifiedDateKeyName = 'FechaNotificado'

        set = {
          ...set,
          [ notifiedDateKeyName ]: moment().toISOString()
        }
        break
      case 'Eliminado':
        let deletedDateKeyName = 'Fecha eliminado'
        if ( client === 'helvex' )
          deletedDateKeyName = 'FechaEliminado'

        set = {
          ...set,
          [ deletedDateKeyName ]: moment().toISOString()
        }
        break
    }

    http( {
      url: `/api/dataSource/${ client }/datos/findAndUpdate`,
      method: 'PUT',
      data: {
        steps: props.withFilter.state.steps || [],
        set
      }
    } )
      .then( response => {
        reload()
      } )
  }

  return (
    <button
      className="btn danger"
      onClick={ toggle }
      disabled={ status === '' }
    >
      update all records

      { isOpen ?
        <Confirm
          icon="FiGitCommit"
          message="do you want to update all filtered records?"
          buttons={ [
            {
              icon: 'FiCheck',
              label: 'yes',
              type: 'yes',
              action
            },
            {
              icon: 'FiX',
              label: 'no',
              type: 'no',
              action: () => { toggle() }
            }
          ] }
          close={ toggle }
        />
      : null }
    </button>
  )
}

export default withReload( withFilter( withList( UpdateAll ) ) )
