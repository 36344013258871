import { useState } from 'react'
import moment from 'moment'
import http from '../../../http'
import { InputText, Select } from '../../../components/Form'
import { Row } from '../../../components/Layout'

const ProductForm = ( {
  client,
  schema,
  identificador,
  close = () => {},
} ) => {
  const [ model, setModel ] = useState( {
    obra_interno: '',
    identificador: identificador || '',
    titulo: '',
    subtitulo: '',
    autor_bibliografico: '',
    editor_bibliografico: '',
    traductor_bibliografico: '',
    tipo_obra: '',
    date_add: moment().startOf( 'day' )
  } )

  const updateModel = ( e ) => {
    const { name, value } = e.target

    setModel( { ...model, [ name ]: value } )
  }

  const save = ( e ) => {
    e.preventDefault()

    http
      .post( `/api/proxy/${ client.name }/collection/catalog_aux/create`, {
        ...model
      } )
      .then( () => {
        close()
      } )
  }

  return <form onSubmit={ save }
    className="hs gap"
  >
    <Row>
      <InputText
        name="obra_interno" 
        model={ model }
        updateModel={ updateModel }
        required={ true }
      />

      <InputText
        name="identificador" 
        model={ model }
        updateModel={ updateModel }
        required={ true }
      />
    </Row>

    <Row>
      <InputText
        className="two"
        name="titulo" 
        model={ model }
        updateModel={ updateModel }
        required={ true }
      />

      <Select
        endpoint={ `/api/dataSource/${ client.name }/catalog/distinct` }
        params={ { field: 'tipo_obra' } }
        name="tipo_obra"
        label="tipo de obra"
        model={ model }
        updateModel={ updateModel }
        required={ true }
      />
    </Row>

    <Row>
      <InputText
        name="subtitulo" 
        model={ model }
        updateModel={ updateModel }
      />
    </Row>

    <Row>
      <InputText
        name="autor_bibliografico" 
        label="autor bibliogr&aacute;fico"
        model={ model }
        updateModel={ updateModel }
        required={ true }
      />
    </Row>

    <Row>
      <InputText
        name="editor_bibliografico" 
        label="editor bibliogr&aacute;fico"
        model={ model }
        updateModel={ updateModel }
        required={ true }
      />

      <InputText
        name="traductor_bibliografico" 
        label="traductor bibliogr&aacute;fico"
        model={ model }
        updateModel={ updateModel }
      />
    </Row>

    <Row
      className="reverse"
    >
      <button
        className="btn frm accent"
      >
        guardar
      </button>
    </Row>
  </form>
}

export default ProductForm
