import React, { useState } from 'react'
import http from '../../../../http'
import { withFilter } from '../../../../context/Filter'
import { withList } from '../../../../context/List'
import { withReload } from '../../../../context/Reload'
import moment from 'moment'
import Confirm from '../../../../components/Confirm'

function UpdateAll( props ) {
  const {
    client,
    value,
    withReload: { reload }
  } = props

  const [ isOpen, setIsOpen ] = useState( false )

  function toggle( e ) {
    setIsOpen( ! isOpen )
  }

  function action( e ) {
    // value
    let set = { 'Tipo de pagina': value }

    if ( value === 'Fake' )
      set = { ...set, Estado: 'Verificado' }

    http( {
      url: `/api/dataSource/${ client }/datos/findAndUpdate`,
      method: 'PUT',
      data: {
        steps: props.withFilter.state.steps || [],
        set
      }
    } )
      .then( response => {
        reload()
      } )
  }

  return (
    <button
      className="btn danger"
      onClick={ toggle }
      disabled={ value === '' }
    >
      update all records

      { isOpen ?
        <Confirm
          icon="FiGitCommit"
          message="do you want to update all filtered records?"
          buttons={ [
            {
              icon: 'FiCheck',
              label: 'yes',
              type: 'yes',
              action
            },
            {
              icon: 'FiX',
              label: 'no',
              type: 'no',
              action: () => { toggle() }
            }
          ] }
          close={ toggle }
        />
      : null }
    </button>
  )
}

export default withReload( withFilter( withList( UpdateAll ) ) )
