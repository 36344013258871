import copy from 'copy-to-clipboard'

import {
  FiCopy
} from 'react-icons/fi'

const CopyField = ( {
  name,
  value,
  span
} ) => {

  return <div className={ `hs ${ span || 'one' } nogap` } >
    <label>{ name }</label>

    <div className="vs one nogap">
      <input
        className="one"
        value={ value }
        size={ 1 }
        disabled
      />

      <button
        title={ `copy ${ name }` }
        onClick={ () => copy( value ) }
      >
        <FiCopy />
      </button>
    </div>
  </div>
}

export default CopyField
