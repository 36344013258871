import React, { useState } from 'react'
import Select from '../../../../components/Select'
import UpdateSelected from './_UpdateSelected'
import UpdateAll from './_UpdateAll'

function SourceType( props ) {
  const [ value, setValue ] = useState( '' )
  const {
    client,
    total
  } = props

  function onValueChange( e, toggle ) {
    setValue( e.target.value )
    toggle()
  }

  return (
    <div className="vs inline-controls">
      <Select
        className="one full"
        placeholder="Tipo de p&aacute;gina"
        name="TipoPagina"
        value={ value }
        onChange={ onValueChange }
        multiple={ false }
        options={ [
          'Comercio electrónico',
          'Fake',
          'Ilegal Download',
          'Lectura en línea',
          'Mensajería instantanea',
          'Mobile app',
          'Motores de búsqueda',
          'Página almacén',
          'Página enlace',
          'Redes sociales',
          'Reproducción ilegal',
          'Torrent',
          'Venta ilegal'
        ] }
      />

      <UpdateSelected
        client={ client }
        value={ value }
      />

      <UpdateAll
        client={ client }
        value={ value }
      />
    </div>
  )
}

export default SourceType
