import B0olean from './_B0olean'
import Select from './_Select'

const EditableField = ( props ) => {
  const { type } = props.__input
  let c = <>unsupported editable field</>

  if ( type === 'boolean' ) {
    c = <B0olean { ...props }  />
  }

  if ( type === 'select' ) {
    c = <Select { ...props }  />
  }

  return <div className="one vs">
    { c }
  </div>
}

export default EditableField
