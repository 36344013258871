import { useState, useEffect } from 'react'
import http from 'http'
import { withSharedStorage } from 'context/SharedStorage'

const Info = ( {
  domain,
  withSharedStorage: { storage: { client, schema } }
} ) => {
  const [ state, setState ] = useState()

  useEffect( () => {
    http
      .post( `/api/proxy/${ client.name }/contact/checkItemsByDomain`, {
        schemaName: schema.name,
        domain
      } )
      .then( ( { data } ) => {
        const { total, missingProducts } = data
        setState( { total, missing: missingProducts.length } )
      } )
  }, [ domain ] )

  if ( ! state ) return null

  return <div className="hs">
    { state.missing > 0 ?
      <div className="card warning">
        falta el <strong>{ state.missing * 100 / state.total }%</strong> de los productos involucrados en esta operacion. de un total de <strong>{ state.total }</strong> se cuenta con <strong>{ state.total - state.missing }</strong>.
      </div>
    :
      <div className="card success">
        se cuenta con el <strong>100%</strong> de los productos involucrados en esta operacion.
      </div>
    }
  </div>
}

export default withSharedStorage( Info )
