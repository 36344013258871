import { useState } from 'react'
import { withSharedStorage } from 'context/SharedStorage'
import { withList } from 'context/List'
import { withReload } from 'context/Reload'
import http from 'http'
import Confirm from 'components/Confirm'

const UpdateSelection = ( {
  value,
  withList: { list, clear },
  withSharedStorage: { storage: { client, schema } },
  withReload: { reload }
} ) => {
  const [ isOpen, setIsOpen ] = useState( false )

  const toggle = () => {
    setIsOpen( ! isOpen )
  }

  const action = () => {
    http( {
      url: `/api/proxy/${ client.name }/collection/${ schema.name }/updateMany`,
      method: 'PUT',
      data: {
        match: { _id: { $in: [ ...list ] } },
        set: { ...value }
      }
    } )
      .then( response => {
        clear()
        reload()
      } )
  }

  return <button
    className="btn accent"
    onClick={ toggle }
    disabled={ value === '' || list.size === 0 }
  >
    update selected records { list.size > 0 ? `(${ list.size })` : '' }

    { isOpen ? <Confirm
      icon="FiGitCommit"
      message="do you want to update all selected items?"
      buttons={ [
        {
          icon: 'FiCheck',
          label: 'yes',
          type: 'yes',
          action
        },
        {
          icon: 'FiX',
          label: 'no',
          type: 'no',
          action: () => { toggle() }
        }
      ] }
      close={ toggle }
    /> : null }
  </button>
}

export default withSharedStorage( withList( withReload( UpdateSelection ) ) )
