import { useState } from 'react'
import { withSharedStorage } from 'context/SharedStorage'
import { Select } from 'components/Form'
import UpdateSelection from './_UpdateSelection'
import UpdateAll from './_UpdateAll'

const LinkType = ( {
  withSharedStorage: { storage: { client, schema } }
} ) => {
  const [ model, setModel ] = useState( {
    TipoEnlaceDesc: ''
  } )

  const updateModel = ( e ) => {
    const { name, value } = e.target

    setModel( { ...model, [ name ]: value } )
  }

  return <div className="vs">
    <Select
      endpoint={ `/api/proxy/${ client.name }/collection/${ schema.name }/enum` }
      params={ { field: 'TipoEnlaceDesc' } }
      name="TipoEnlaceDesc"
      label="tipo de enlace"
      model={ model }
      updateModel={ updateModel }
      onlyControl={ true }
    />

    <UpdateSelection
      value={ model }
    />

    <UpdateAll
      value={ model }
    />
  </div>
}

export default withSharedStorage( LinkType )
